import React, {useEffect} from 'react';
import {
  isMobile
} from "react-device-detect";
import styled from 'styled-components';
import Layout from '@common/Layout';
import MobileHeader from '@sections/MobileHeader';
import Footer from '@sections/Footer';

import { initUserConversion, loadAccesstrade } from '../helper'

const NotFoundPage = () => {
  useEffect(() => {
    loadAccesstrade();
    initUserConversion();
    if (!isMobile) {
      window.location.replace('https://drobebox.com')
    }
  },[])
  return (
    <Layout>
      <MobileContainer>
        <MobileHeader/>
        <Footer />
        <MobileGrid>
          <Line style={{ borderLeft: 'solid 1px #e3e3e3' }}/>
          <Line />
          <Line />
          <Line />
        </MobileGrid>
      </MobileContainer>
    </Layout>
  
  );
};

const MobileContainer = styled.div`
  display: none;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100%;
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: flex;
  }
`

const MobileGrid = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: flex;
  }
  padding: 0 2rem;
`

const Line = styled.div`
  flex: 1;
  height: 100%;
  opacity: 0.47;
  border-right: solid 1px #e3e3e3;
`

export default NotFoundPage;
